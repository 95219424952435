<template>
	<div class="app">
		<Auth v-if="!profile"></Auth>
		<Chat v-if="profile"></Chat>
		<audio id="newDialog" src="/audio/newDialog.mp3" preload="auto"></audio>
		<audio id="newMessage" src="/audio/newMessage.mp3" preload="auto"></audio>
	</div>
</template>

<script>

import useFetch from "@/plugins/useFetch";

import Auth from "@/components/Auth";
import Chat from "@/Chat";

export default {
	name: "App",
	components:{Auth, Chat},
	data(){
		return {
			isAuthorized:false,
			isWsLoading:false,
			isUserInteract:false,
			profile:null,
			wsClient:null,
		}
	},
	computed:{
		isWsReadyToStart(){
			return this.isAuthorized && this.isWsLoading
		}
	},
	watch:{
		'profile'(profile){
			this.isAuthorized = !!profile
		},
	},

	methods:{
		async wakeup(){
			let profile  = await useFetch('wakeup');
			this.profile = profile;
			this.$root.profile =  profile;
		},
		onUserInteract(){
			if(this.isUserInteract) return;
			this.isUserInteract = true;
			console.log('isUserInteract');
			document.removeEventListener('mousemove', this.onUserInteract, true)
		},
		playNotice(id){
			if(!this.isUserInteract) return false;
			let audio = new Audio(`/audio/${id}.mp3`);
			audio.play();
		},
	},
	async mounted() {
		await this.wakeup();
		window._root = this.$root;
		this.$root.$on('auth::wakeup', () => this.wakeup());
		this.$root.$on('notice::play', (name) => this.playNotice(name));
		document.addEventListener('mousemove', () => this.onUserInteract())

	}
}
</script>

<style scoped>

</style>