<template>
	<div class="dialogs">

		<div class="dialogs-header">
			<Profile></Profile>
		</div>
		<div class="dialogs-search" v-if="false">
			<div class="dialogs-search-icon">
				<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path id="vac-icon-search" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"></path></svg>
			</div>
			<input type="search" placeholder="Поиск" autocomplete="off">
		</div>

		<div class="dialogs-scroll-top" v-if="roomDialogsScrollTop">
			<div class="chat-btn" @click="scrollToTop">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><polyline points="18 15 12 9 6 15"></polyline></svg>
			</div>
		</div>
		<div class="dialogs-list" ref="roomDialogs" @scroll="onDialogsScroll">

			<div ref="roomDialogsInner">
				<div class="dialogs-dialog " :class="{'--is-selected':dialog.id == dialogId, '--is-closed':dialog.isClosed}" @click="setDialog(dialog)"
				     v-for="dialog in items">

					<div class="dialogs-dialog-container">
						<div class="dialogs-dialog-profile"
						     :style="{'--bg-color':$helpers.getPastelColor(dialog.user.name)}">
							<span>{{ $helpers.getFirstWord(dialog.user.name) }}</span></div>

						<div class="dialogs-dialog-main text-ellipsis">
							<div class="dialogs-dialog-main-header">
								<div class="dialogs-dialog-main-header-title text-ellipsis">#{{dialog.id}} {{ dialog.user.name }}</div>
								<div class="dialogs-dialog-main-header-timestamp">{{ $helpers.formatDateFull(dialog.updated) }}</div>
							</div>
							<div class="dialogs-dialog-main-last">
								<span class="dialogs-dialog-main-last-status"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" viewBox="0 0 24 24" class="vac-icon-check"><path id="vac-icon-double-checkmark" d="M18 7l-1.41-1.41-6.34 6.34 1.41 1.41L18 7zm4.24-1.41L11.66 16.17 7.48 12l-1.41 1.41L11.66 19l12-12-1.42-1.41zM.41 13.41L6 19l1.41-1.41L1.83 12 .41 13.41z"></path></svg></span>
								<div class="dialogs-dialog-main-last-message text-ellipsis">
									<div class="dialogs-dialog-main-last-container text-ellipsis">
										<span class="text-ellipsis" v-if="false">{{ dialog.title }}</span>
										<span class="text-ellipsis" v-if="dialog.json">{{dialog.json['lastMessage']}}</span>
									</div>
								</div>

								<div class="dialogs-dialog-main-last-new-messages-cnt" v-if="dialog.newMessages">
									<span>{{dialog.newMessages}}</span>

								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

import Profile from "@/components/Profile";
export default {
	name: "Dialogs",
	components: {Profile},
	data(){
		return {
			result:null,
			dialogId:null,
			isFromWs:false,
			roomDialogsScrollTop:false,
			state:{
				isPagenLoading:false,
			}
		}
	},
	watch:{
		dialogMessagesCnt(val,oldVal){
			if(!val || !this.isFromWs) return false;
			if(oldVal && oldVal > val) return false;
			if(val > 0 && oldVal != val){
				this.$root.$emit('notice::play', 'newMessage');
			}
		},
		roomDialogsScrollTop(parcent){
			if(parcent < 80 || this.state.isPagenLoading) return false;
			this.loadLastDialogs();
		}
	},
	computed:{
		items(){
			if(!this.result || !this.result?.items) return false;
			return this.result?.items.sort((a,b) => {
				if (a.isClosed === b.isClosed) {
					return new Date(b.updated) - new Date(a.updated);
				}
				return a.isClosed - b.isClosed;
			});
		},
		dialogMessagesCnt(){
			if(!this.items || !Array.isArray(this.items)) return 0;
			return this.items.reduce( (summ, item) => summ + item.newMessages, 0 );
		},
		isAllowNextPage(){
			return this.result.pages > this.result.page;
		},
		nextPage(){
			let totalPages = this.result.pages;
			let currentPage = this.result.page;
			if(totalPages > currentPage) currentPage++;
			return currentPage;
		},
	},
	methods:{
		setDialog(dialog){
			if(this.dialogId == dialog.id) return false;
			this.dialogId = dialog.id
			this.$root.$emit('setDialog', dialog);
			this.$root.$emit('drop::closeAll');
		},
		onDialogsScroll(event){
			//const target = event.target;
			const outerDiv = this.$refs['roomDialogs'];
			const innerDiv = this.$refs['roomDialogsInner'];
			const maxScrollLeft = innerDiv.scrollHeight - outerDiv.clientHeight;
			const scrollLeft = outerDiv.scrollTop;
			const scrollPercentage = (scrollLeft / maxScrollLeft) * 100;
			this.roomDialogsScrollTop = parseInt(scrollPercentage)
		},

		async getDialogs(){
			this.result = await this.$useFetch('/support/manager/dialogs');
		},
		async loadLastDialogs(){
			if(!this.isAllowNextPage) return;
			this.state.isPagenLoading = true;
			let params = new URLSearchParams();
			params.set('page', this.nextPage);
			let result = await this.$useFetch('/support/manager/dialogs?'+params.toString())
			this.result.page = result.page;
			if(Array.isArray(result.items)){
				this.result.items = [...this.result.items, ...result.items]
			}
			this.state.isPagenLoading = false;
		},
		scrollToTop(){
			const outerDiv = this.$refs['roomDialogs'];
			outerDiv.scrollTo({top: 0, behavior: 'smooth'});
		},
		removeDialog(dialogId = ''){
			let dialogIndex = this.items.findIndex(i => i.id == dialogId)
			this.items.splice(dialogIndex, 1);
			this.setDialog(this.items[0]);
		}
	},
	mounted() {
		this.getDialogs();

		this.$root.$on('updateState', payload => {
			this.isFromWs = true;
			let dialog = this.items.find(i => i.id == payload?.dialog?.id)
			if(dialog){
				dialog.newMessages = payload?.dialog.newMessages;
				dialog.isClosed = payload?.dialog.isClosed;
				dialog.updated = payload?.dialog.updated;
				dialog.json = payload?.dialog.json;
				if(dialog.isClosed){
					//this.removeDialog(dialog?.id);
				}
			}else{
				this.items.push(payload?.dialog)
				this.$root.$emit('notice::play', 'newDialog');
			}
			setTimeout(() => {this.isFromWs = false}, 1000)
		})
	}
}
</script>
<style lang="scss" scoped>
.dialogs{
	display: flex;
	flex-flow: column;
	flex: 0 0 25%;
	min-width: 260px;
	max-width: 500px;
	position: relative;
	background: var(--chat-sidemenu-bg-color);
	height: 100%;
	border-top-left-radius: var(--chat-container-border-radius);
	border-bottom-left-radius: var(--chat-container-border-radius);

	&-scroll-top{
		position: absolute;
		right: 2rem;
		bottom: 1rem;
		z-index: 1;

	}
	&-search{
		position: sticky;
		display: flex;
		align-items: center;
		height: 64px;
		padding: 0 15px;
		&-icon{
			display: flex;
			position: absolute;
			left: 30px;
			svg{
				width: 18px;
				height: 18px;
			}
		}
		input{
			height: 38px;
			width: 100%;
			background: var(--chat-bg-color-input);
			color: var(--chat-color);
			font-size: 15px;
			outline: 0;
			caret-color: var(--chat-color-caret);
			padding: 10px 10px 10px 40px;
			border: 1px solid var(--chat-sidemenu-border-color-search);
			border-radius: 20px;
		}
	}
	&-list{
		flex: 1;
		position: relative;
		max-width: 100%;
		padding: 0 10px 5px;
		overflow-y: auto;
	}
	&-dialog{
		border-radius: 8px;
		align-items: center;
		display: flex;
		flex: 1 1 100%;
		margin-bottom: 5px;
		padding: 0 14px;
		position: relative;
		min-height: 71px;
		transition: background-color .3s cubic-bezier(.25,.8,.5,1);

		&-selected{
			color: var(--chat-sidemenu-color-active) !important;
			background: var(--chat-sidemenu-bg-color-active) !important;
		}



		&:hover{
			background: var(--chat-sidemenu-bg-color-hover);
		}

		&:not(.--is-selected){
			cursor: pointer;
		}
		&.--is-selected{
			color: var(--chat-sidemenu-color-active) !important;
			background: var(--chat-sidemenu-bg-color-active) !important;
		}
		&.--is-closed{
			background: #f6f6f6;
			opacity: 0.7;
		}

		&-container{
			display: flex;
			flex: 1;
			align-items: center;
			width: 100%;
		}
		&-profile{
			height: 42px;
			width: 42px;
			min-height: 42px;
			min-width: 42px;
			margin-right: 15px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: var(--bg-color, yellow);
		}
		&-main{
			width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			&-header{
				display: flex;
				align-items: center;
				line-height: 25px;
				&-title{
					flex: 1;
					color: var(--chat-room-color-username);
					font-weight: 500;
				}
				&-timestamp{
					margin-left: 5px;
					font-size: 11px;
					color: var(--chat-room-color-timestamp);
				}
			}
			&-last{
				display: flex;
				align-items: center;
				font-size: 12px;
				line-height: 19px;
				color: var(--chat-room-color-message);
				&-status{
					svg{
						display: flex;
						vertical-align: middle;
						height: 14px;
						width: 14px;
						margin-top: -2px;
						margin-right: 2px;
						path{
							fill: var(--chat-icon-color-checkmark);
						}
					}
				}
				&-message{

				}
				&-new-messages-cnt{
					width: 14px;
					margin-left: auto;
					display: flex;
					align-items: flex-end;
					position: relative;
					span{
						background: var(--chat-icon-color-audio-cancel);
						color: #fff;
						aspect-ratio: 1;
						width: 15px;
						height: 15px;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 10px;
						border-radius: 50%;
					}
				}
				&-container{
					display: inline;
				}
			}
		}


	}
}
</style>