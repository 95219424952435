<template>
	<button class="drop-content-button" :class="{'--is-active':active}" @click="setClick"><slot></slot></button>
</template>
<script>
	export default {
		props:{
			closeOnSelect:{
				type:Boolean,
				default:false,
			},
			active:{
				type:Boolean,
				default:false,
			}
		},
		methods:{
			setClick($event){

				this.$emit('click');

				if(this.closeOnSelect){
					setTimeout(() => {
						this.$root.$emit('drop::closeAll')
					}, 5)
				}
			}
		}
	}
</script>
<style lang="scss">
	.drop-content-button{
		display: block;
		text-decoration: none;
		background-color: var(--drop-content-button-background);
		color: var(--drop-content-button-color);
		border: none;
		width: 100%;
		text-align: left;
		padding: var(--drop-content-button-padding-tb) var(--drop-content-button-padding-lr);
		cursor: pointer;
		&.--is-active{
			font-weight: 500;
			background-color: var(--drop-content-button-background-active);
			color: var(--drop-content-button-color-active);
		}
		&:hover:not(.--is-active){
			background-color: var(--drop-content-button-background-hovered);
			color: var(--drop-content-button-color-hovered);
		}
	}
</style>
