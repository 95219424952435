import routes from "@/plugins/routes";
export default async function (routeName, options){
    let route = routes[routeName] ? routes[routeName] : routeName ;
    let method = options?.method ? options.method : "GET"
    let nudeResult = options?.nudeResult == true;
    let init  = {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }
    if(options?.payload) init['body'] = JSON.stringify(options?.payload ? options.payload : {});
    if(options?.body) init['body'] = options?.body;
    let response = await fetch(route, init);
    const result = await response.json();
    if(nudeResult) return result;
    return result?.data;
}