<template>
	<div class="drop-trigger" :class="['noselect']" @click="toggle()" >
		<slot></slot>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				state:{
					isLoading:false
				}
			}
		},
		methods:{
			show(){
				this.$parent.$emit('show')
			},
			hide(){
				this.$parent.$emit('hide')
			},
			toggle(){
				if(!this.isToggle) return false;
				this.$parent.$emit('toggle')
			}
		}
	}
</script>

