<template>
	<div class="support">
		<div class="support-container">
			<div class="support-chat">
				<div class="support-chat-dialogs"><Dialogs></Dialogs></div>
				<div class="support-chat-messages"><Messages></Messages></div>
			</div>
		</div>
	</div>
</template>

<script>

import usePull from "@/plugins/usePull";
import Profile from "@/components/Profile";
import Dialogs from "@/components/Dialogs";
import Messages from "@/components/Messages";


export default {
	name: 'Chat',
	components: {
		Profile,
		Dialogs,
		Messages
	},
	data(){
		return {
			wsClient:null,
			isWsLoading:false,
		}
	},
	computed:{
		profile(){
			return this.$root.profile;
		},
		isAuthorized(){
			return !!this.profile
		},
		isWsReadyToStart(){
			return this.isAuthorized && this.isWsLoading
		}
	},
	watch:{
		'wsClient'(wsClient){
			this.isWsLoading = !!wsClient
		},
		'isWsReadyToStart'(){
			this.wsClient.start();
		}
	},


	mounted() {
		this.wsClient = new usePull();
		this.wsClient.onMessage = (event)=> {
			//let evtName = event?.text.command;
			this.$root.$emit('updateState', event?.text.params)
			console.log(event?.text.command, event?.text.params)
		};

		this.$root.$on('dialogs:toggle', () => {
			let isDialogOpen = this.getParam('isDialogOpen', true)
			isDialogOpen = !isDialogOpen
			this.setParam('isDialogOpen', isDialogOpen);
			this.$root.params = this.getParams();

		})
	}
}
</script>

<style lang="scss">

.support{
	padding: 1rem;

	&-container{
		width: 100%;
		display: block;
		max-width: 100%;
		background: var(--chat-content-bg-color);
		color: var(--chat-color);
		overflow-wrap: break-word;
		white-space: normal;
		border: var(--chat-container-border);
		border-radius: var(--chat-container-border-radius);
		box-shadow: var(--chat-container-box-shadow);
		-webkit-tap-highlight-color: transparent;
		box-sizing: border-box;
		height: calc(-2rem + 100vh);
	}

}

.support-chat{
	height: 100%;
	display: flex;
	&-dialogs{
		display: flex;
		flex-flow: column;
		flex: 0 0 25%;
		min-width: 260px;
		max-width: 500px;
		position: relative;
		background: var(--chat-sidemenu-bg-color);
		height: 100%;
		border-top-left-radius: var(--chat-container-border-radius);
		border-bottom-left-radius: var(--chat-container-border-radius);
	}
	&-messages{
		position: relative;
		height: 100%;
		flex: 1;
		overflow: hidden;
		display: flex;
		flex-flow: column;
	}
}

</style>
