<template>
	<div class="support-auth">
		<div class="support-auth-bg"></div>
		<div class="support-auth-content">
			<form class="support-auth-form" v-if="!timerData" @submit.prevent="sendCode()">
				<div class="support-auth-logo"></div>
				<div class="support-auth-input">
					<div class="support-auth-input-title">Логин</div>
					<input type="text" placeholder="Логин" v-model="payload.login" required>
				</div>
				<div class="support-auth-button">
					<button type="submit" :class="{'--is-loading':isLoading}"><span>Отправить код</span></button>
				</div>
			</form>


			<form class="support-auth-form" v-if="timerData" @submit.prevent="login()">
				<div class="support-auth-logo"></div>
				<div class="support-auth-input" >
					<div class="support-auth-input-title" v-if="false">Код</div>
					<input type="text" placeholder="Код" v-model.number="payload.code" required>
				</div>
				<div class="support-auth-button">
					<button type="submit" :disabled="isLoginButtonDisabled" :class="{'--is-loading':isLoading}"><span>Вход</span></button>
				</div>

				<div class="support-auth-timer">
					<div v-if="timerData.timeout">
						Повторить можно через {{$helpers.secondsToMinutes(timerData.timeout)}} минут
					</div>
					<div v-if="!timerData.timeout">
						<a href="#" @click.prevent="sendCode()">Отправить код повторно</a>
					</div>
				</div>


			</form>
		</div>
	</div>
</template>

<script>
import useFetch from "@/plugins/useFetch";
export default {
	name: "Auth",
	data(){
		return {
			timer:null,
			timerData:null,
			isLoading: false,
			payload:{
				login:'',
				code:'',
			}
		}
	},
	watch:{
		'timerData.timeout'(value){
			if(value){
				this.timer = setTimeout(() => {
					this.timerData.timeout--;
					this.setTimerData(this.timerData)
				}, 1000)
			}
		}
	},
	computed:{
		isLoginButtonDisabled(){
			let numbers = parseInt(this.payload.code);
			let string = new String(numbers)
			return string.length != 6;
		}
	},
	methods:{
		async login(){
			this.isLoading = true;
			const result = await useFetch('/support/manager/verify', {
				method:'POST',
				nudeResult:true,
				body:JSON.stringify( this.payload )
			});
			this.isLoading = false;
			if(result.status == 'success') {
				this.$root.$emit('auth::wakeup');
				window.localStorage.removeItem('auth-timer-data')
			}
		},
		async logout(){

			const result = await this.$useFetch('/support/manager/logout', {
				method:'POST',
				nudeResult:true,
			})

			if(result.status == 'success') {
				this.$root.$emit('auth::wakeup');
				window.localStorage.removeItem('auth-timer-data')
				this.timerData = null;
				clearTimeout(this.timer);
				this.timer = null;
			}

		},
		async sendCode(){
			this.isLoading = true;
			const result = await useFetch('/support/manager/getCode', {
				method:'POST',
				nudeResult:true,
				body:JSON.stringify({
					login:this.payload.login
				})
			});
			this.isLoading = false;
			this.setTimerData(result.data);
		},
		setTimerData(data){
			window.localStorage.setItem('auth-timer-data', JSON.stringify(data))
			this.timerData = data;
		},
		getTimerData(){
			try {
				let timerData = window.localStorage.getItem('auth-timer-data')
				if(!timerData) return false;
				timerData = JSON.parse(timerData)
				this.timerData = timerData;
			}catch (e){

			}
		}
	},
	mounted() {
		this.getTimerData();
		this.$root.$on('auth::logout', () => {
			this.logout()
		})

	}
}
</script>

<style lang="scss" scoped>
.support-auth{
	display: block;
	width: 100vw;
	height: 100vh;
	background: #fff;
	position: relative;
	&-bg{
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: #ffffff url("../../public/img/main.png") no-repeat center right;
		background-size: cover;
		z-index: 1;
	}
	&-content{
		width: 100%;
		margin:  0 auto;
		max-width: 80%;
		height: 100%;
		z-index: 2;
		position: relative;
		display: flex;
		align-items: center;
	}

	&-timer{
		text-align: center;
		font-size: 14px;
		padding: 1rem;
		box-sizing: border-box;
	}

	&-form{

		border: 0px solid #ddd;
		border-radius: 0px;
		padding: 2rem;
		width: 100%;
		max-width: 300px;
	}
	&-logo{
		background: url("../../public/img/preview-logo.svg") no-repeat center;
		width: 100%;
		height: 50px;
		display: block;
		margin-bottom: 10px;
		background-size: contain;
	}
	&-input{
		display: block;
		width: 100%;
		margin-bottom: 1rem;
		&-title{
			font-size: 16px;
			display: block;
			margin-bottom: 1px;
		}
		input{
			padding: 10px;
			width: 100%;
			display: block;
			box-sizing: border-box;
			border-radius: 5px;
			border: 1px solid #ddd;
		}
	}
	&-button{
		button{
			display: block;
			padding: 10px;
			display: block;
			width: 100%;
			background: #165cc8;
			color: #fff;
			border-radius: 5px;
			border: none;
			cursor: pointer;
			font-size: 14px;
			&:disabled{
				background: #ddd;
				cursor: default;
			}

			&.--is-loading{
				--color:#165cc8;
				//	--border-color:var(--active-border-color);
				//	--background-color:var(--active-background-color);
				--fill:#fff;
				--size:1em;

				position: relative;
				cursor: default;

				* {
					opacity: 0;
				}

				span{
					visibility: hidden;
				}
				&:before{
					opacity: 1;
					content: '';
					position: absolute;
					left: 50%;
					top: 50%;
					border: 2px solid var(--fill);
					border-top-color: transparent;
					border-bottom-color: transparent;
					margin: -10px -10px;
					animation: rotating 800ms linear infinite;
					width: 16px;
					height: 16px;
					border-radius: 50%;
				}
			}
		}
	}
}
@keyframes rotating {
	100% {
		transform: rotate(360deg);
	}
}
</style>