class usePull {
    _host;
    _reconnectTimer;
    _status;
    _attempt = 0;
    _config;
    _wsurl;
    _channel;
    onMessage;
    onOpen;
    onClosed;
    onError;

    constructor(host = "") {
        this._host = host;
    }

    async _getSessId()  {
        const endpoint = this._host + '/sesstoken';
        const response = await fetch(endpoint);
        const json = await response.json();
        return json['data'];
    }

    async _fetchConfig() {
        const sessid = await this._getSessId();
        const params = new URLSearchParams();
        params.append('sessid', sessid);
        params.append('halt', sessid);
        params.append('cmd[serverTime]', 'server.time?');
        params.append('cmd[configGet]', 'pull.config.get?CACHE=N');
        const response = await fetch(this._host + '/rest/batch.json?logTag=pull.config', {
            method: "POST", // or 'PUT'
            body: params,
        });

        const json = await response.json();

        return json;
    }

    _setStatus(status){
        this.status = status;
        console.log(status);
    }

    _getWsUrl(polconfig, channel = 'private') {
        const url = polconfig.result.result.configGet.server.websocket_secure;
        const changels = polconfig.result.result.configGet.channels;
        let changelId = changels[channel].id;

        return url + '?CHANNEL_ID=' + changelId + '&format=json&revision=19';
    }

    async start()  {
        try {
            this._attempt = 0;
            console.log('start ws');
            this._config = await this._fetchConfig();
            this._wsurl = this._getWsUrl(this._config);
            this._socketUp(this._wsurl);
        } catch (e) {
            console.log(e);
            //to do nothing
        }
    }

    _stop() {
        if (this._channel != null) {
            this._channel.close();
        }
        if (this._reconnectTimer != null) {
            clearTimeout(this._reconnectTimer)
        }
    }

    async _socketUp(url)  {
        if (this._status == "connecting") {
            return;
        }

        this._setStatus("connecting");

        try {
            this._channel = new WebSocket(url);
            this._channel.onmessage = (e) => {
                const data = this._parse(e.data);
                for (var index in data) {
                    this._onEvent(data[index]);
                }
            }
            this._channel.onopen = (e) => {
                this._setStatus("online");
                if (typeof this.onOpen === 'function')
                    this.onOpen(e);
            };
            this._channel.onclose = (e) => {
                this._setStatus("closed");
                if (typeof this.onClosed === 'function')
                    this.onClosed(e);
            }
            this._channel.onerror = (e) => {
                this._setStatus("error");
                if (typeof this.onError === 'function')
                    this.onError(e);
            }
            if (this._reconnectTimer != null) {
                clearTimeout(this._reconnectTimer)
            }
            this._attempt = 0;
        } catch (e) {
            console.log(e);
            this._setStatus("offline");
            this._repeat(url);
        } finally {
            this._attempt++;
        }
    }

    async _channelExpire(event) {
        try {
            const reconnectData = event.text.params;
            if (reconnectData.action == 'reconnect') {
                this._socketUp(this._makeNewUrl(reconnectData.new_channel.id));
                console.log('reconnect ws');
            } else if (reconnectData.action == 'get_config') {
                console.log('get_config ws');
                _config = await this._fetchConfig();
                this._wsurl = this._getWsUrl(this._config);
                this._socketUp(this._wsurl);
            }
        } catch (e) {
            console.log(e);
        }
    }

    _makeNewUrl(id) {
        return _this._wsurl + '?CHANNEL_ID=$id&format=json&revision=19 ';
    }

    _serverRestart()  {
        console.log('server restart');
        _start();
    }

    _repeat(url) {
        this._reconnectTimer = setTimeout(()=>{
            this._socketUp(url);
        }, this.getReconnectSeconds())
    }

    getReconnectSeconds() {
        if (this._attempt == 0) {
            return 5;
        } else if (this._attempt > 0) {
            return 15;
        } else if (this._attempt > 3) {
            return 45;
        } else if (thisl_attempt > 10) {
            return 60 * 10;
        }
        return 60 * 60;
    }

    _parse(pullEvent) {
        let result = [];
        const dataArray = pullEvent.match(/#!NGINXNMS!#(.*?)#!NGINXNME!#/gm);
        if (dataArray === null)
        {
            const text = "\n========= PULL ERROR ===========\n" +
                "Error type: parseResponse error parsing message\n" +
                "\n" +
                "Data string: " + pullEvent + "\n" +
                "================================\n\n";
            console.warn(text);
            return result;
        }
        for (let i = 0; i < dataArray.length; i++)
        {
            dataArray[i] = dataArray[i].substring(12, dataArray[i].length - 12);
            if (dataArray[i].length <= 0)
            {
                continue;
            }

            let data
            try
            {
                data = JSON.parse(dataArray[i])
            } catch (e)
            {
                continue;
            }

            result.push(data);
        }
        return result;
    }
    _onEvent(event) {
        if (event.text.command == 'channel_expire') {
            this._channelExpire(event);
        } else if (event.text.command == 'server_restart') {
            this._serverRestart(event);
        }
        this.onMessage(event);
    }
}
export default usePull;



