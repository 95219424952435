import colors from './pastelColors.json'

const getPastelColor = (str) => {
    if (str.length === 0) {
        return ''; // Если строка пустая, возвращаем пустую строку
    }
    const firstLetter = str[0].toUpperCase();
    return colors[firstLetter];
}
const getFirstWord = s => s.split("")[0];

const scrollTo = (elementSelector = '', offsetTop = 0) => {
    if(!elementSelector) return;
    const elementContainer = document.querySelector(elementSelector);
    if(!elementContainer) return;
    window.scrollTo({
        top: elementContainer.offsetTop - offsetTop,
        behavior: "smooth",
    });
}

const isMobile = function (s) {
    return window.innerWidth < 808;
}

const removeSpace = function (s) {
    return String(s).replace(/\s/g,'');
}

const addSpace = function (n) {
    return String(removeSpace(n)).replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ')
}

const fUpper = function(s){
    if(!s) return ;
    return s[0].toUpperCase() + s.slice(1);
}

const fLower = function(s){
    if(!s) return ;
    return s[0].toLowerCase() + s.slice(1);
}

const getUniqueId = () => {
    return (new String((Math.random() + 1).toString(36).substring(2))).toUpperCase()
}

const num_word = function(value, words){
    value = Math.abs(value) % 100;
    var num = value % 10;
    if(value > 10 && value < 20) return words[2];
    if(num > 1 && num < 5) return words[1];
    if(num == 1) return words[0];
    return words[2];
}

const timeSince = (date) => {

    let seconds = Math.floor((new Date() - new Date(date)) / 1000);
    let interval = seconds / 31536000;
    if (interval > 1) {
        let year = Math.floor(interval)
        return year + " " + num_word(year, ['год', 'года', 'лет']);
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        let mounth = Math.floor(interval)
        return mounth +  " " + num_word(mounth, ['месяц', 'месяца', 'месяцев']);
    }
    interval = seconds / 86400;
    if (interval > 1) {
        let days = Math.floor(interval);
        return days + " " + num_word(days, ['день', 'дня', 'дней']);
    }
    interval = seconds / 3600;
    if (interval > 1) {
        let seconds = Math.floor(interval)
        return seconds + " " + num_word(seconds, ['час', 'часа', 'часов']);
    }
    interval = seconds / 60;
    if (interval > 1) {
        let minuts = Math.floor(interval)
        return minuts +  " " + num_word(minuts, ['минута', 'минуты', 'минут']);
    }
    seconds = Math.floor(seconds);
    return seconds + " " + num_word(seconds, ['сукенда', 'секунды', 'секунд']);
}

const formatPhone = phone => {
    if(!phone) return false;
    phone = phone.replace(/[^\d]/g, "");
    if (phone.length == 10) {
        return phone.replace(/(\d{3})(\d{3})(\d{4})/, "+7 ($1) $2-$3");
    }
    if (phone.length == 11) {
        phone = phone.slice( 1 );
        return phone.replace(/(\d{3})(\d{3})(\d{4})/, "+7 ($1) $2-$3");
    }
    return null;
}

const formatDate = date => {
    if(!date) return false;
    date = new Date(date);
    var options = {  year: '2-digit', month: '2-digit', day: '2-digit' };
    return date.toLocaleDateString("ru-RU", options)
}

const formatDateReadble = date => {
    if(!date) return false;
    date = new Date(date);
    var options = {  year: '2-digit', month: 'long', day: 'numeric' };
    return date.toLocaleDateString("ru-RU", options)
}

const formatTime = date => {
    if(!date) return false;
    date = new Date(date);
    var options = {  year: 'numeric', month: 'long', day: 'numeric' };
    return date.getHours() + ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
}

const formatDateFull = date => {
    if(!date) return false;
    let today = new Date();
    date = new Date(date);
    let timer = new Date(date);

    let result = [] ;
    if(today.getFullYear() == date.getFullYear()){
        result.push(date.toLocaleDateString("ru-RU", {  month: 'long', day: 'numeric' }));
    }else{
        result.push(date.toLocaleDateString("ru-RU", {  year: 'numeric', month: 'long', day: 'numeric' }));
    }

    if(timer.getHours() || timer.getMinutes()){
        result.push(timer.getHours() + ':' + (timer.getMinutes() < 10 ? '0' + timer.getMinutes() : timer.getMinutes()));
    }

    return result.join(' в ');
}


const formatDateHumanize = date => {
    return timeSince(date)
}

const metaTitle = title => {
    const prefix = 'PrideFest - система организации праздников'
    if(!title) return prefix;
    return title + ' | ' +  prefix;
}

const secondsToMinutes = (s) => {
    let m = Math.trunc(s / 60) + ''
    s = (s % 60) + ''
    return m.padStart(2, 0) + ':' + s.padStart(2, 0)
}
export default {
    formatDateHumanize,
    formatDateReadble,
    secondsToMinutes,
    formatDateFull,
    getPastelColor,
    getFirstWord,
    formatPhone,
    formatDate,
    formatTime,
    timeSince,
    num_word,
}
