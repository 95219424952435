import Vue from 'vue'
import App from './App.vue'
import helpers from "@/plugins/helpers";
import useFetch from "@/plugins/useFetch";
import defaultParams from "@/plugins/defaultParams";
import localStorage from "@/plugins/localStorage";
import './registerServiceWorker'
require('@/assets/main.css')

Vue.config.productionTip = false
Vue.prototype.$useFetch = useFetch;
Vue.prototype.$helpers = helpers;
Vue.prototype.$profile = null;

new Vue({
    render: function (h) { return h(App) },
}).$mount('#app')

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js').then(registration => {
            console.log('SW registered: ', registration);
        }).catch(registrationError => {
            console.log('SW registration failed: ', registrationError);
        });
    });
}

