<template>
	<div class="drop" @click="click" @mouseover.prevent="mouseover"  @mouseleave.prevent="mouseleave" :class="{'--open':state.isOpen}">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		props: {
			type:{
				type:String,
				default:'click'
			}
		},
		data(){
			return {
				state:{
					isOpen:false,
				},
			}
		},
		mounted() {


			document.addEventListener('click', event => {

				const isCurrentDrop = event.target.closest('.drop') == this.$el;

				if(!isCurrentDrop){
					this.close();
					return;
				}

				const preventClose = event.target.closest('.preventClose');
				if(preventClose) return;

			});


		//	this.$on('toggle', () => this.state.isOpen = !this.state.isOpen)

			this.$root.$on('drop::closeOther', runningDrop => {
				if(runningDrop != this) this.close();
			})

			this.$root.$on('drop::closeAll', () => {
				this.close();
			})

		},
		methods:{


			emitEvent(event = ''){
				this.$root.$emit('drop::'+event, this)
			},
			mouseover(){
			//	if(this.type == 'hover'){ this.open(); }
			},
			mouseleave(){
			//	if(this.type == 'hover'){ this.close(); }
			},
			click(event){
				if(!this.state.isOpen &&  this.type == 'click') {
					this.open();
				}
				else{
					const isCurrentDrop = event.target.closest('.drop') == this.$el;
					const clickOnBtn = event.target.closest('.drop-btn');
					if(isCurrentDrop && clickOnBtn){
						this.close()
					}
				}
			},
			open(){
				this.emitEvent('closeOther')
				this.state.isOpen = true;
			},
			close(){
				this.state.isOpen = false;
			},
			toggle(){
				this.state.isOpen = !this.state.isOpen;
			}
		}
	}
</script>

<style lang="scss">
	.drop{

		/*
			drop-btn
		 */
		--drop-btn-border:1px solid #ddd;
		--drop-btn-border-radius:3px;
		--drop-btn-background:#fff;
		--drop-btn-cursor:pointer;

		//hover
		--drop-btn-border-hover:1px solid var( --color-pink);
		--drop-btn-background-hover:#fff;

		//active
		--drop-btn-border-active:1px solid  var(--color-gray);
		--drop-btn-background-active:#fff;



		--drop-btn-icon-size:12px;
		--drop-btn-icon-offset-y:1px;
		--drop-btn-icon-stroke:#000;
		--drop-btn-icon-stroke-width:1px;
		--drop-btn-preloader-size: 20px;
		--drop-btn-preloader-stroke-width: 10px;
		--drop-btn-preloader-stroke-color: var(--color-background);
		--drop-btn-padding-tb:10px;
		--drop-btn-padding-lr:10px;
		--drop-btn-gap:10px;
		--drop-btn-slot-offset-y:-1px;

		--drop-container-width:100%;
		--drop-container-max-width:unset;

		--drop-content-width:auto;
		--drop-content-min-width:220px;

		--drop-content-button-background:#fff;
		--drop-content-button-background-hovered:whitesmoke; // smoke
		--drop-content-button-background-active:#f1f1f1; // smoke
		--drop-content-button-color:#282828;
		--drop-content-button-color-hovered:#282828;
		--drop-content-button-color-active:#282828;
		--drop-content-button-padding-tb:10px;
		--drop-content-button-padding-lr:20px;


		/*
			drop-content-filter
		 */
		--drop-content-filter-padding:0;
		--drop-content-filter-border-bottom:1px solid #ddd;
		--drop-content-filter-input-padding:13px 20px 13px;
		--drop-content-filter-input-border:none;
		--drop-content-filter-input-border-radius: 20px 20px 0 0;
		--drop-content-filter-input-font-size:14px;


		position: relative;

		.drop-btn{
			position: relative;
			padding-right: calc(var(--drop-btn-gap) + var(--drop-btn-icon-size));
			min-height: var(--drop-btn-icon-size);
			border: var(--drop-btn-border);
			background: var(--drop-btn-background);
			border-radius: var(--drop-btn-border-radius);
			cursor: var(--drop-btn-cursor);


			&:hover{
				border: var(--drop-btn-border-hover, var(--drop-btn-border));
				background: var(--drop-btn-background-hover, var(--drop-btn-background));
			}

			&-slot{
				padding: var(--drop-btn-padding-tb) var(--drop-btn-padding-lr);
				transform: translateY(var(--drop-btn-slot-offset-y));
			}


			&-preloader,
			&-arrow{
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				width: var(--drop-btn-icon-size);
				height: var(--drop-btn-icon-size);
				top: calc(50% + var(--drop-btn-icon-offset-y));
				right: var(--drop-btn-gap);
				transform:translateY(-50%);
			}
			&-preloader{
				width: var(--drop-btn-preloader-size);
				height: var(--drop-btn-preloader-size);
			}
		}
		.drop-container{
			display: none;
			position: absolute;
			transform: translateY(5px);
			top: 100%;
			right: -1rem;
			padding-top: 10px;
			box-sizing: border-box;
			width: var(--drop-container-width);
			max-width: var(--drop-container-max-width);
			min-width:min-content;
			&::before {
				content: '';
				position: absolute;
				right: 20px;
				top: 0;
				width: 0;
				height: 0;
				border-left: 9px solid transparent;
				border-right: 9px solid transparent;
				border-bottom: 10px solid #d9d9d9;
			}
		}
		.drop-content{


			min-width: var(--drop-content-min-width);
			width: var(--drop-content-width);
			background: #fff;
			box-shadow: 0px 4px 20px rgb(0 0 0 / 20%);
			border-radius: 8px;
			box-sizing: border-box;
			border: 1px solid #d9d9d9;

			&-filter{
				padding: var(--drop-content-filter-padding);
				border-bottom: var(--drop-content-filter-border-bottom);
				input{
					display: block;
					width: 100%;
					padding: var(--drop-content-filter-input-padding);
					border:var(--drop-content-filter-input-border);
					border-radius:var(--drop-content-filter-input-border-radius);
					font-size: var(--drop-content-filter-input-font-size);
				}
			}
			&-list{
				max-height: 300px;
				margin: 0;
				overflow: auto;
				padding: 10px 0;

				&-title{
					padding: 0px 20px 5px;
					font-size: 14px;
					font-weight: 500;
					line-height: 1em;
				}
				&-text{
					padding: 0px 20px 5px;
					font-size: 14px;
					font-weight: 400;
					line-height: 1em;
					b{
						font-weight: 500;
					}
				}

				&-selected{
					border-bottom: 1px solid #ededed;
					padding-bottom: 5px;
					margin-bottom: 5px;
					.drop-content-list-item-off{
						position: absolute;
						right: 20px;
						top: 50%;
						transform: translateY(-50%);
						display: flex;
						align-items: center;
						justify-content: center;
						background: #fff;
						padding-left: 5px;
						background: linear-gradient(90deg, #ffffffa3, white);
						.close{
							--volume: 1px;
						}
					}
				}
				&-item{
					white-space: nowrap;
					font-size: 16px;
					padding: 6px 20px;
					line-height: 1.4em;
					display: block;
					color: #282828;
					font-weight: 400;
					position: relative;
				}
			}
			&-footer{
				display: block;
				padding: 1rem;
				position: relative;
				border-top: 1px solid #ddd;
				.btn{
					border-radius: 5px;
					height: auto;
					font-size: 14px;
					width: 100%;
					padding: 12px 10px;
				}
			}
		}
		&.--open{
			.drop-btn{
				&-arrow{
					transform:translateY(-50%) rotate(180deg);
				}
				/*
				&:hover{
					border: var(--drop-btn-border-active, var(--drop-btn-border));
					background: var(--drop-btn-background-active, var(--drop-btn-background));
				}
				*/

			}
			.drop-container{
				display: block;
				z-index: 100;
			}
		}
	}
</style>
