<template>
	<a :href="link" target="_blank" class="message-product" title="Открыть товар">
		<div class="message-product-image">
			<img :src="data.picture":alt="data.name">
		</div>
		<div class="message-product-title">{{data.name}}</div>
	</a>
</template>

<script>
export default {
	name:"MessageProduct",
	props:["data"],
	computed:{
		link(){
			return `https://talap.wigital.ru/bitrix/admin/iblock_element_edit.php?IBLOCK_ID=26&type=1c_catalog&lang=ru&ID=${this.data.id}&find_section_section=-1&WF=Y`
		}
	}
}
</script>

<style lang="scss" scoped>
.message-product{
	max-width: 300px;
	color: #000;
	.message-product-image{
		width: 100%;
		margin-bottom: 10px;
		img{
			display: block;
			width: 100%;
		}
	}
	.message-product-title{

	}
}
</style>