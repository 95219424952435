<template>
	<div class="profile">
		<div class="profile-avatar" :style="{'--bg-color':$helpers.getPastelColor(profile.name)}">
			<span>{{ $helpers.getFirstWord(profile.name) }}</span>
		</div>
		<div class="profile-name" >
			<div class="profile-name-name">{{profile.name}}</div>
			<div class="profile-name-login">Manager #{{profile.id}}</div>

		</div>
		<div class="profile-controlls" >
			<UiDrop>
				<UiDropTrigger style="cursor: pointer">
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
				</UiDropTrigger>
				<UiDropContent>
					<UiDropContentList>

						<UiDropContentButton @click="() => $root.$emit('auth::logout')">Выход</UiDropContentButton>


					</UiDropContentList>
				</UiDropContent>
			</UiDrop>
		</div>

	</div>
</template>

<script>
import UiDrop from "/src/components/Drop";
import UiDropTrigger from "/src/components/Drop/Trigger";
import UiDropContent from "/src/components/Drop/Content/index";
import UiDropContentList from "/src/components/Drop/Content/List";
import UiDropContentButton from "/src/components/Drop/Content/Button";
export default {
	name: "Profile",
	components:{UiDrop, UiDropTrigger, UiDropContent, UiDropContentList, UiDropContentButton},
	computed:{
		profile(){
			return this.$root.profile;
		}
	},
	methods:{

	}
}
</script>

<style lang="scss" scoped>
.profile{
	display: grid;
	grid-template-columns: 38px 1fr;
	position: relative;
	align-items: center;
	gap: 1rem;
	padding: 10px 20px;
	height: 44px;

	.drop{
		--drop-btn-icon-size: 14px;
		--drop-content-min-width: 150px;
	}
	&-controlls{
		position: absolute;
		right: 10px;
		top: 0;
		bottom: 0;
		width: 3rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&-name{
		font-size: 14px;
		&-name{
			font-weight: 600;

		}
		span{
			display: block;
		}
	}
	&-avatar{
		display: flex;
		justify-content: center;
		background: var(--bg-color);
		aspect-ratio: 1;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
	}

	&-dropdown{
		display: none;
	}
}
</style>